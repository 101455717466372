























































import { defineComponent } from '@vue/composition-api'
import { UserApplication } from '@/api/interfaces/userApplication'
import { User } from '@/api/interfaces/user'
import Initials from '@/components/molecules/initials.vue'

export default defineComponent({
  name: 'SelectTrainingOfficer',
  components: { Initials },
  props: {
    value: {
      type: [Object, String, Array],
      required: false,
      default: null,
    },
    items: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    classes: {
      type: String,
      required: false,
    },
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    appendIcon: {
      type: String,
      required: false,
      default: 'mdi-chevron-down',
    },
    returnObject: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data () {
    return {
      trainingOfficer: this.value,
    }
  },
  watch: {
    value: {
      handler () {
        (this.trainingOfficer as UserApplication) = this.value
      },
    },
  },
  methods: {
    itemValue (item: User | UserApplication) {
      if ('userApplication' in item && item.userApplication) {
        return item.userApplication['@id']
      }

      return item['@id']
    },
    onInput (value: string) {
      this.$emit('input', value)
    },
    onChange (value: string) {
      this.$emit('change', value)
    },
    itemSearch (item: User): string {
      return item.firstName + ' ' + (item.lastName || '')
    },
  },
})
