





































































































































































import SelectTrainingOfficer from '@/components/molecules/select/trainingOfficer.vue'
import { defineComponent, ref } from '@vue/composition-api'
import { userConnectedHasGroup } from '@/composables/UserGrant'
import { groupTrainingOfficerDriverProgram, groupTrainingOfficerOthers, groupOfficerSupplierRelationship } from '@/config-constantes'
import { mapActions, mapMutations, mapState } from 'vuex'
import { User } from '@/api/interfaces/user'
import { UserApplication } from '@/api/interfaces/userApplication'
import { TrainingOfficerView } from '@/api/enums/trainingOfficerView'
import TopBar from '@/components/organisms/o-top-bar.vue'
import { format, formatISO } from 'date-fns'
import frLocale from 'date-fns/locale/fr'
import DialogTransferLogisticSession from '@/components/molecules/trainingOfficer/dialogTransferLogisticSession.vue'
import DataTableTrainingOfficer from '@/components/molecules/trainingOfficer/dataTableTrainingOfficer.vue'
import router from '@/router'
import { arrayFromUrl } from '@/composables/planning/paramsUrlPlanning'
import { useDate } from '@/composables/date'
import { constructUrlQueryString } from '@/store/api-plateform-utils'

export default defineComponent({
  name: 'TrainingOfficerList',
  setup () {
    const query = {
      ...router.app.$route.query,
    }
    const filters = ref({
      monthSelected: query.monthSelected || '',
      trainingOfficers: arrayFromUrl(query.trainingOfficers) || [] as Array<string>,
      view: query.view || '',
    })
    delete query.monthSelected
    delete query.trainingOfficers
    delete query.view

    const defaultHeaders = {
      status: [],
      statusCircuit: [],
      statusSupplierRelationship: [],
      periodDays: [],
      commentary: '',
      commentaryViewOthers: '',
      infoAddressHousing: '',
      commentaryHousing: '',
      commentaryHousingSupplierRelationship: '',
      caterer: '',
      commentaryCaterer: '',
      commentaryTrainingLocation: '',
      commentarySupplierRelationshipOfficer: '',
      commentaryVehicle: '',
      sendVehicleFolderAt: '',
      'trainers.name': '',
      'trainingLocation.city': '',
      'trainingSessions.trainingLocation.text': '',
      'trainingLocation.type': [],
      'trainingSessions.trainingType.id': [],
      'trainingSessions.needTrainings.company.name': '',
      'trainingSessions.needTrainings.trainee.email': '',
      'trainingSessions.needTrainings.trainee.name': '',
      'trainingSessions.needTrainings.trainee.phones': '',
      'trackingDateLogisticSessions.sendConventionAt': '',
      'trackingDateLogisticSessions.sendConvocationAt': '',
      'trackingDateLogisticSessions.sendSmsAndMailCLientAt': '',
      'trackingDateLogisticSessions.sendMailCarDealerAt': '',
      'trackingDateLogisticSessions.sendSmsAt': '',
      'trackingDateLogisticSessions.sendPostTrainingDocumentsAt': '',
    }

    const filtersHeaders = ref({ ...defaultHeaders })

    return {
      filters,
      filtersHeaders,
      defaultHeaders,
    }
  },
  components: {
    DataTableTrainingOfficer,
    DialogTransferLogisticSession,
    TopBar,
    SelectTrainingOfficer,
  },
  watch: {
    filtersHeaders: {
      async handler (newValue, oldValue) {
        if (!this.loadAfterWatchFiltersHeaders) {
          this.loadAfterWatchFiltersHeaders = true
          return
        }

        if (JSON.stringify(newValue) === JSON.stringify(oldValue)) {
          return
        }
        try {
          this.isLoadingLogisticSessionsWithFiltersHeaders = true
          await this.loadLogisticSessions(false)
        } finally {
          this.isLoadingLogisticSessionsWithFiltersHeaders = false
        }
      },
      deep: true,
    },
  },
  data () {
    return {
      title: this.$t('training_officers.list.title_header'),
      monthPickerDialog: false,
      monthsSelectable: [] as Array<string>,
      loadAfterWatchFiltersHeaders: true,
      itemsView: [
        { text: this.$t('training_officers.filters.view.driver_program'), value: TrainingOfficerView.DRIVER_PROGRAM },
        { text: this.$t('training_officers.filters.view.others'), value: TrainingOfficerView.OTHERS },
        { text: this.$t('training_officers.filters.view.supplier_relationship'), value: TrainingOfficerView.SUPPLIER_RELATIONSHIP },
      ],
      showDialogTransferLogisticSession: false,
      isLoadingLogisticSessionsWithFiltersHeaders: false,
    }
  },
  async created () {
    this.resetLogisticSessionsOfTrainingOfficer()
    this.setFiltersTrainingOfficerViewFromUserConnected()
    if (this.filters.monthSelected === '') {
      this.monthsSelectable = this.getMonthsFilters(new Date())
      this.selectMonth(this.monthsSelectable[0], false)
    } else {
      this.monthsSelectable = this.getMonthsFilters(new Date(this.filters.monthSelected as string))
    }

    const defaultTrainingOfficer = this.getDefaultTrainingOfficer()
    if (
      this.filters.trainingOfficers.length > 0 ||
      (defaultTrainingOfficer && defaultTrainingOfficer.userApplication)
    ) {
      this.filters.trainingOfficers = this.filters.trainingOfficers.length > 0 ? this.filters.trainingOfficers : [((defaultTrainingOfficer as User).userApplication as UserApplication)['@id'] as string]
      this.filters.view = this.filters.view !== '' ? this.filters.view : this.getDefaultViewSelected()

      await Promise.all([
        this.loadTrainingOfficersDriverProgram({ pagination: false }),
        this.loadTrainingOfficersOthers({ pagination: false }),
        this.loadOfficersSupplierRelationship({ pagination: false }),
        this.loadLogisticSessions(),
      ])
    } else {
      this.filters.view = this.filters.view !== '' ? this.filters.view : TrainingOfficerView.DRIVER_PROGRAM

      await Promise.all([
        this.loadTrainingOfficersDriverProgram({ pagination: false }),
        this.loadTrainingOfficersOthers({ pagination: false }),
        this.loadOfficersSupplierRelationship({ pagination: false }),
      ])
    }
  },
  methods: {
    ...mapActions('trainingOfficer', {
      loadTrainingOfficersDriverProgram: 'loadTrainingOfficersDriverProgram',
      loadTrainingOfficersOthers: 'loadTrainingOfficersOthers',
      loadOfficersSupplierRelationship: 'loadOfficersSupplierRelationship',
      loadLogisticSessionsOfTrainingOfficer: 'loadLogisticSessionsOfTrainingOfficer',
      resetLogisticSessionsOfTrainingOfficer: 'resetLogisticSessionsOfTrainingOfficer',
    }),
    ...mapMutations('trainingOfficer', {
      setLogisticSessionsOfTrainingOfficer: 'setLogisticSessionsOfTrainingOfficer',
    }),
    ...mapActions('filtersTrainingOfficerView', {
      saveFiltersTrainingOfficerView: 'save',
    }),
    addParamsToLocation () {
      let query: any = {
        ...this.filters,
      }
      query = Object.fromEntries(
        Object.entries(query).filter(([key, value]) => !!value),
      )
      router.replace({ name: 'Training Officers', query: query })
    },
    getDefaultTrainingOfficer (): User | null {
      if (
        userConnectedHasGroup(groupTrainingOfficerDriverProgram) ||
        userConnectedHasGroup(groupTrainingOfficerOthers) ||
        userConnectedHasGroup(groupOfficerSupplierRelationship)
      ) {
        return this.userConnected
      }

      return null
    },
    getDefaultViewSelected (): TrainingOfficerView {
      if (userConnectedHasGroup(groupTrainingOfficerDriverProgram)) {
        return TrainingOfficerView.DRIVER_PROGRAM
      }

      if (userConnectedHasGroup(groupTrainingOfficerOthers)) {
        return TrainingOfficerView.OTHERS
      }

      if (userConnectedHasGroup(groupOfficerSupplierRelationship)) {
        return TrainingOfficerView.SUPPLIER_RELATIONSHIP
      }

      return TrainingOfficerView.DRIVER_PROGRAM
    },
    reloadLogisticSessions () {
      this.setLogisticSessionsOfTrainingOfficer([])

      this.loadLogisticSessions()
    },
    async loadLogisticSessions (changeStateIsLoading = true) {
      this.addParamsToLocation()
      if (this.filters.trainingOfficers.length === 0) {
        return
      }
      await this.loadLogisticSessionsOfTrainingOfficer({
        view: this.filters.view,
        queryParams: this.getQueryParams(),
        changeStateIsLoading,
      })
      await this.saveFilters()
    },
    async saveFilters () {
      await this.saveFiltersTrainingOfficerView({
        ...this.filters,
        ...this.filtersHeaders,
      })
    },
    initFiltersHeaders () {
      return this.defaultHeaders
    },
    async changeView () {
      this.setLogisticSessionsOfTrainingOfficer([])
      this.loadAfterWatchFiltersHeaders = false
      this.filtersHeaders = this.initFiltersHeaders()
      let users = []
      if (this.filters.view === TrainingOfficerView.DRIVER_PROGRAM) {
        users = this.listTrainingOfficersDriverProgram.filter((trainingOfficer: User) => this.filters.trainingOfficers.includes((trainingOfficer.userApplication as UserApplication)['@id'] as string))
        if (users.length === 0) {
          this.filters.trainingOfficers = []
        }
      } else if (this.filters.view === TrainingOfficerView.OTHERS) {
        users = this.listTrainingOfficersOthers.filter((trainingOfficer: User) => this.filters.trainingOfficers.includes((trainingOfficer.userApplication as UserApplication)['@id'] as string))
        if (users.length === 0) {
          this.filters.trainingOfficers = []
        }
      } else if (this.filters.view === TrainingOfficerView.SUPPLIER_RELATIONSHIP) {
        users = this.listOfficersSupplierRelationship.filter((trainingOfficer: User) => this.filters.trainingOfficers.includes((trainingOfficer.userApplication as UserApplication)['@id'] as string))
        if (users.length === 0) {
          this.filters.trainingOfficers = []
        }
      }

      if (users.length > 0) {
        this.filters.trainingOfficers = users.map((user: User) => (user.userApplication as UserApplication)['@id'] as string)
        this.loadLogisticSessions()
      } else {
        await this.saveFilters()
      }
    },
    selectMonth (date: string, reload = true) {
      this.filters.monthSelected = formatISO(new Date(date))
      if (reload) {
        this.monthsSelectable = this.getMonthsFilters(new Date(date))
        this.reloadLogisticSessions()
      }
    },
    formatMonth (date: string) {
      const dateConverted = new Date(date)
      const string = format(dateConverted, 'MMMM yyyy', {
        locale: frLocale,
      })
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    getMonthsFilters (firstMonth: Date): Array<string> {
      const months = []
      for (let i = 0; i < 4; i++) {
        const date = formatISO(new Date(firstMonth.getFullYear(), firstMonth.getMonth() + i, 1))
        months.push(date)
      }

      return months
    },
    addMonth (step: number) {
      const date = new Date(this.filters.monthSelected as string)
      date.setMonth(date.getMonth() + step)
      this.selectMonth(formatISO(date))
    },
    getQueryParams () {
      const startAtAfter = this.filters.monthSelected
      const startAtBefore = useDate().getLastMonthDayOfDate(this.filters.monthSelected as string)

      return {
        'startAt[after]': startAtAfter,
        'startAt[before]': startAtBefore,
        idTrainingOfficer: this.filters.trainingOfficers,
        ...this.filtersHeaders,
      }
    },
    setFiltersTrainingOfficerViewFromUserConnected () {
      if (this.userConnected.userApplication.filtersTrainingOfficerView) {
        this.filters = {
          monthSelected: this.userConnected.userApplication.filtersTrainingOfficerView.filters.monthSelected,
          trainingOfficers: this.userConnected.userApplication.filtersTrainingOfficerView.filters.trainingOfficers,
          view: this.userConnected.userApplication.filtersTrainingOfficerView.filters.view,
        }

        this.loadAfterWatchFiltersHeaders = false
        this.filtersHeaders = {
          status: this.userConnected.userApplication.filtersTrainingOfficerView.filters.status ?? [],
          statusCircuit: this.userConnected.userApplication.filtersTrainingOfficerView.filters.statusCircuit ?? [],
          statusSupplierRelationship: this.userConnected.userApplication.filtersTrainingOfficerView.filters.statusSupplierRelationship ?? [],
          periodDays: this.userConnected.userApplication.filtersTrainingOfficerView.filters.periodDays,
          commentary: this.userConnected.userApplication.filtersTrainingOfficerView.filters.commentary,
          commentaryViewOthers: this.userConnected.userApplication.filtersTrainingOfficerView.filters.commentaryViewOthers,
          infoAddressHousing: this.userConnected.userApplication.filtersTrainingOfficerView.filters.infoAddressHousing,
          commentaryHousing: this.userConnected.userApplication.filtersTrainingOfficerView.filters.commentaryHousing,
          commentaryHousingSupplierRelationship: this.userConnected.userApplication.filtersTrainingOfficerView.filters.commentaryHousing,
          caterer: this.userConnected.userApplication.filtersTrainingOfficerView.filters.caterer,
          commentaryCaterer: this.userConnected.userApplication.filtersTrainingOfficerView.filters.commentaryCaterer,
          commentaryTrainingLocation: this.userConnected.userApplication.filtersTrainingOfficerView.filters.commentaryTrainingLocation,
          commentarySupplierRelationshipOfficer: this.userConnected.userApplication.filtersTrainingOfficerView.filters.commentarySupplierRelationshipOfficer,
          commentaryVehicle: this.userConnected.userApplication.filtersTrainingOfficerView.filters.commentaryVehicle,
          sendVehicleFolderAt: this.userConnected.userApplication.filtersTrainingOfficerView.filters.sendVehicleFolderAt,
          'trainers.name': this.userConnected.userApplication.filtersTrainingOfficerView.filters['trainers.name'],
          'trainingLocation.city': this.userConnected.userApplication.filtersTrainingOfficerView.filters['trainingLocation.city'],
          'trainingSessions.trainingLocation.text': this.userConnected.userApplication.filtersTrainingOfficerView.filters['trainingSessions.trainingLocation.text'],
          'trainingLocation.type': this.userConnected.userApplication.filtersTrainingOfficerView.filters['trainingLocation.type'] ?? [],
          'trainingSessions.trainingType.id': this.userConnected.userApplication.filtersTrainingOfficerView.filters['trainingSessions.trainingType.id'],
          'trainingSessions.needTrainings.company.name': this.userConnected.userApplication.filtersTrainingOfficerView.filters['trainingSessions.needTrainings.company.name'],
          'trainingSessions.needTrainings.trainee.email': this.userConnected.userApplication.filtersTrainingOfficerView.filters['trainingSessions.needTrainings.trainee.email'],
          'trainingSessions.needTrainings.trainee.name': this.userConnected.userApplication.filtersTrainingOfficerView.filters['trainingSessions.needTrainings.trainee.name'],
          'trainingSessions.needTrainings.trainee.phones': this.userConnected.userApplication.filtersTrainingOfficerView.filters['trainingSessions.needTrainings.trainee.phones'],
          'trackingDateLogisticSessions.sendConventionAt': this.userConnected.userApplication.filtersTrainingOfficerView.filters['trackingDateLogisticSessions.sendConventionAt'],
          'trackingDateLogisticSessions.sendConvocationAt': this.userConnected.userApplication.filtersTrainingOfficerView.filters['trackingDateLogisticSessions.sendConvocationAt'],
          'trackingDateLogisticSessions.sendSmsAndMailCLientAt': this.userConnected.userApplication.filtersTrainingOfficerView.filters['trackingDateLogisticSessions.sendSmsAndMailCLientAt'],
          'trackingDateLogisticSessions.sendMailCarDealerAt': this.userConnected.userApplication.filtersTrainingOfficerView.filters['trackingDateLogisticSessions.sendMailCarDealerAt'],
          'trackingDateLogisticSessions.sendSmsAt': this.userConnected.userApplication.filtersTrainingOfficerView.filters['trackingDateLogisticSessions.sendSmsAt'],
          'trackingDateLogisticSessions.sendPostTrainingDocumentsAt': this.userConnected.userApplication.filtersTrainingOfficerView.filters['trackingDateLogisticSessions.sendPostTrainingDocumentsAt'],
        }
      }
    },
  },
  computed: {
    ...mapState('trainingOfficer', {
      listTrainingOfficersDriverProgram: 'listTrainingOfficersDriverProgram',
      listTrainingOfficersOthers: 'listTrainingOfficersOthers',
      listOfficersSupplierRelationship: 'listOfficersSupplierRelationship',
      loadingTrainingOfficersDriverProgram: 'loadingTrainingOfficersDriverProgram',
      loadingTrainingOfficersOthers: 'loadingTrainingOfficersOthers',
      loadingOfficersSupplierRelationship: 'loadingOfficersSupplierRelationship',
      logisticSessions: 'logisticSessionsOfTrainingOfficer',
      loadingLogisticSessions: 'loadingLogisticSessionsOfTrainingOfficer',
    }),
    ...mapState('user', {
      userConnected: 'userConnected',
    }),
    isLoadingTrainingOfficers (): boolean {
      return this.loadingTrainingOfficersDriverProgram || this.loadingTrainingOfficersOthers || this.loadingOfficersSupplierRelationship
    },
    itemsTrainingOfficers (): Array<User> {
      if (this.filters.view === TrainingOfficerView.DRIVER_PROGRAM) {
        return this.listTrainingOfficersDriverProgram
      } else if (this.filters.view === TrainingOfficerView.OTHERS) {
        return this.listTrainingOfficersOthers
      } else if (this.filters.view === TrainingOfficerView.SUPPLIER_RELATIONSHIP) {
        return this.listOfficersSupplierRelationship
      }

      return []
    },
    dateIso: {
      get (): string {
        const monthSelected = new Date(this.filters.monthSelected as string)
        return monthSelected.getFullYear() + '-' + (monthSelected.getMonth() + 1).toString().padStart(2, '0')
      },
      set (value: string) {
        if (value) {
          const date = new Date(value)
          date.setDate(1)
          this.selectMonth(formatISO(date))
        }
      },
    },
    exportLogisticSessionsUrl () {
      const query = this.getQueryParams()
      query.idTrainingOfficer = this.filters.trainingOfficers
      const option = constructUrlQueryString(query as any)
      return `api/logistic_sessions/training_officers/export/view/${(this.filters as any).view}${option}`
    },
  },
  beforeRouteLeave (to, from, next) {
    if (to.name === 'LogisticSession Edit') {
      this.$store.commit('navigation/resetPile')
      this.$store.commit('navigation/addRoute', from)
    }
    next()
  },
})
