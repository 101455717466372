var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{class:'elevation-1 my-3 odd-background-lines ' + ('table-' + _vm.view),style:(("--col-offset-width: " + _vm.columnsOffsetWidth + "px;")),attrs:{"loading":_vm.isLoading,"headers":_vm.getHeaders,"items":_vm.items,"hide-default-footer":"","disable-pagination":"","fixed-header":"","height":"80vh","loading-text":"","no-data-text":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.initials",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"secondary"}},[_vm._v(" "+_vm._s(item.trainingOfficer.user.initials)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.canEditLogisticSession)?_c('router-link',{attrs:{"to":{
        name: 'LogisticSession Edit',
        params: { idLogisticSession: item.id.toString() },
      }}},[_c('v-avatar',{staticClass:"rounded-lg",attrs:{"tile":"","color":"success"}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)],1):_vm._e()]}},{key:"header.status",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('list-select-filter',{attrs:{"multiple":"","translation":_vm.$t('training_officers.list.filters.status'),"items":_vm.$appConfig.itemsStatusLogisticSession,"item-text":function (item) { return item.text; },"item-value":function (item) { return item.value; }},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {status: $event}))}},model:{value:(_vm.filtersHeaders.status),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, "status", $$v)},expression:"filtersHeaders.status"}})],1)]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('select-status-logistic-session',{attrs:{"label":_vm.$t('training_officers.list.status'),"hide-details":"","outlined":""},on:{"change":function($event){return _vm.updateLogisticSessionProperty(item.id, 'status', $event)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"header.statusCircuit",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('list-select-filter',{attrs:{"multiple":"","translation":_vm.$t('training_officers.list.filters.status'),"items":_vm.$appConfig.itemsStatusLogisticSessionCircuit,"item-text":function (item) { return item.text; },"item-value":function (item) { return item.value; }},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {statusCircuit: $event}))}},model:{value:(_vm.filtersHeaders.statusCircuit),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, "statusCircuit", $$v)},expression:"filtersHeaders.statusCircuit"}})],1)]}},{key:"item.statusCircuit",fn:function(ref){
      var item = ref.item;
return [_c('select-status-logistic-session-circuit',{attrs:{"label":_vm.$t('training_officers.list.status_circuit'),"hide-details":"","outlined":""},on:{"change":function($event){return _vm.updateLogisticSessionProperty(item.id, 'statusCircuit', $event)}},model:{value:(item.statusCircuit),callback:function ($$v) {_vm.$set(item, "statusCircuit", $$v)},expression:"item.statusCircuit"}})]}},{key:"header.statusSupplierRelationship",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('list-select-filter',{attrs:{"multiple":"","translation":_vm.$t('training_officers.list.filters.status'),"items":_vm.$appConfig.itemsStatusLogisticSessionSupplierRelationship,"item-text":function (item) { return item.text; },"item-value":function (item) { return item.value; }},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {statusSupplierRelationship: $event}))}},model:{value:(_vm.filtersHeaders.statusSupplierRelationship),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, "statusSupplierRelationship", $$v)},expression:"filtersHeaders.statusSupplierRelationship"}})],1)]}},{key:"item.statusSupplierRelationship",fn:function(ref){
      var item = ref.item;
return [_c('select-status-logistic-session-supplier-relationship',{attrs:{"label":_vm.$t('training_officers.list.status_supplier_relationship'),"hide-details":"","outlined":""},on:{"change":function($event){return _vm.updateLogisticSessionProperty(item.id, 'statusSupplierRelationship', $event)}},model:{value:(item.statusSupplierRelationship),callback:function ($$v) {_vm.$set(item, "statusSupplierRelationship", $$v)},expression:"item.statusSupplierRelationship"}})]}},{key:"item.startAt",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.startAt))+" ")]}},{key:"header.startAt",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('date-field-filter',{attrs:{"label":_vm.$t('training_officers.list.filters.date'),"min":_vm.monthSelected,"max":_vm.useDate().getLastMonthDayOfDate(_vm.monthSelected),"date-to-show":_vm.monthSelected,"range":""},on:{"validate":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {periodDays: $event}))},"clear":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {periodDays: null}))}},model:{value:(_vm.filtersHeaders.periodDays),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, "periodDays", $$v)},expression:"filtersHeaders.periodDays"}})]}},{key:"header.commentary",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.commentary')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {commentary: $event}))}},model:{value:(_vm.filtersHeaders.commentary),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, "commentary", $$v)},expression:"filtersHeaders.commentary"}})],1)]}},{key:"item.commentary",fn:function(ref){
      var item = ref.item;
return [_c('v-textarea',{attrs:{"no-resize":"","auto-grow":""},on:{"blur":function($event){return _vm.updateLogisticSessionProperty(item.id, 'commentary', item.commentary)}},model:{value:(item.commentary),callback:function ($$v) {_vm.$set(item, "commentary", $$v)},expression:"item.commentary"}})]}},{key:"header.commentaryViewOthers",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.commentary')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {commentaryViewOthers: $event}))}},model:{value:(_vm.filtersHeaders.commentaryViewOthers),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, "commentaryViewOthers", $$v)},expression:"filtersHeaders.commentaryViewOthers"}})],1)]}},{key:"item.commentaryViewOthers",fn:function(ref){
      var item = ref.item;
return [_c('v-textarea',{attrs:{"no-resize":"","auto-grow":""},on:{"blur":function($event){return _vm.updateLogisticSessionProperty(item.id, 'commentaryViewOthers', item.commentaryViewOthers)}},model:{value:(item.commentaryViewOthers),callback:function ($$v) {_vm.$set(item, "commentaryViewOthers", $$v)},expression:"item.commentaryViewOthers"}})]}},{key:"item.infoAddressHousing",fn:function(ref){
      var item = ref.item;
return [(item.nameHousing)?_c('div',[_vm._v(" "+_vm._s(item.nameHousing)+" ")]):_vm._e(),_vm._v(" "+_vm._s(item.addressHousing)+" "+_vm._s(item.cityHousing)+" "+_vm._s(item.zipCodeHousing)+" ")]}},{key:"header.infoAddressHousing",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.address')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {infoAddressHousing: $event}))}},model:{value:(_vm.filtersHeaders.infoAddressHousing),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, "infoAddressHousing", $$v)},expression:"filtersHeaders.infoAddressHousing"}})],1)]}},{key:"item.commentaryHousing",fn:function(ref){
      var item = ref.item;
return [_c('v-textarea',{attrs:{"no-resize":"","auto-grow":""},on:{"blur":function($event){return _vm.updateLogisticSessionProperty(item.id, 'commentaryHousing', item.commentaryHousing)}},model:{value:(item.commentaryHousing),callback:function ($$v) {_vm.$set(item, "commentaryHousing", $$v)},expression:"item.commentaryHousing"}})]}},{key:"header.commentaryHousing",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.commentary')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {commentaryHousing: $event}))}},model:{value:(_vm.filtersHeaders.commentaryHousing),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, "commentaryHousing", $$v)},expression:"filtersHeaders.commentaryHousing"}})],1)]}},{key:"item.commentaryHousingSupplierRelationship",fn:function(ref){
      var item = ref.item;
return [_c('v-textarea',{attrs:{"no-resize":"","auto-grow":""},on:{"blur":function($event){return _vm.updateLogisticSessionProperty(item.id, 'commentaryHousingSupplierRelationship', item.commentaryHousingSupplierRelationship)}},model:{value:(item.commentaryHousingSupplierRelationship),callback:function ($$v) {_vm.$set(item, "commentaryHousingSupplierRelationship", $$v)},expression:"item.commentaryHousingSupplierRelationship"}})]}},{key:"header.commentaryHousingSupplierRelationship",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.commentary')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {commentaryHousingSupplierRelationship: $event}))}},model:{value:(_vm.filtersHeaders.commentaryHousingSupplierRelationship),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, "commentaryHousingSupplierRelationship", $$v)},expression:"filtersHeaders.commentaryHousingSupplierRelationship"}})],1)]}},{key:"item.caterer",fn:function(ref){
      var item = ref.item;
return [_c('v-textarea',{attrs:{"no-resize":"","auto-grow":""},on:{"blur":function($event){return _vm.updateLogisticSessionProperty(item.id, 'caterer', item.caterer)}},model:{value:(item.caterer),callback:function ($$v) {_vm.$set(item, "caterer", $$v)},expression:"item.caterer"}})]}},{key:"header.caterer",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.commentary')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {caterer: $event}))}},model:{value:(_vm.filtersHeaders.caterer),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, "caterer", $$v)},expression:"filtersHeaders.caterer"}})]}},{key:"item.commentaryCaterer",fn:function(ref){
      var item = ref.item;
return [_c('v-textarea',{attrs:{"no-resize":"","auto-grow":""},on:{"blur":function($event){return _vm.updateLogisticSessionProperty(item.id, 'commentaryCaterer', item.commentaryCaterer)}},model:{value:(item.commentaryCaterer),callback:function ($$v) {_vm.$set(item, "commentaryCaterer", $$v)},expression:"item.commentaryCaterer"}})]}},{key:"header.commentaryCaterer",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.commentary')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {commentaryCaterer: $event}))}},model:{value:(_vm.filtersHeaders.commentaryCaterer),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, "commentaryCaterer", $$v)},expression:"filtersHeaders.commentaryCaterer"}})]}},{key:"item.commentaryTrainingLocation",fn:function(ref){
      var item = ref.item;
return [_c('v-textarea',{attrs:{"no-resize":"","auto-grow":""},on:{"blur":function($event){return _vm.updateLogisticSessionProperty(item.id, 'commentaryTrainingLocation', item.commentaryTrainingLocation)}},model:{value:(item.commentaryTrainingLocation),callback:function ($$v) {_vm.$set(item, "commentaryTrainingLocation", $$v)},expression:"item.commentaryTrainingLocation"}})]}},{key:"header.commentaryTrainingLocation",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.commentary')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {commentaryTrainingLocation: $event}))}},model:{value:(_vm.filtersHeaders.commentaryTrainingLocation),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, "commentaryTrainingLocation", $$v)},expression:"filtersHeaders.commentaryTrainingLocation"}})]}},{key:"item.commentarySupplierRelationshipOfficer",fn:function(ref){
      var item = ref.item;
return [_c('v-textarea',{attrs:{"no-resize":"","auto-grow":""},on:{"blur":function($event){return _vm.updateLogisticSessionProperty(item.id, 'commentarySupplierRelationshipOfficer', item.commentarySupplierRelationshipOfficer)}},model:{value:(item.commentarySupplierRelationshipOfficer),callback:function ($$v) {_vm.$set(item, "commentarySupplierRelationshipOfficer", $$v)},expression:"item.commentarySupplierRelationshipOfficer"}})]}},{key:"header.commentarySupplierRelationshipOfficer",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.commentary')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {commentarySupplierRelationshipOfficer: $event}))}},model:{value:(_vm.filtersHeaders.commentarySupplierRelationshipOfficer),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, "commentarySupplierRelationshipOfficer", $$v)},expression:"filtersHeaders.commentarySupplierRelationshipOfficer"}})]}},{key:"item.commentaryVehicle",fn:function(ref){
      var item = ref.item;
return [_c('v-textarea',{attrs:{"no-resize":"","auto-grow":""},on:{"blur":function($event){return _vm.updateLogisticSessionProperty(item.id, 'commentaryVehicle', item.commentaryVehicle)}},model:{value:(item.commentaryVehicle),callback:function ($$v) {_vm.$set(item, "commentaryVehicle", $$v)},expression:"item.commentaryVehicle"}})]}},{key:"header.commentaryVehicle",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.commentary')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {commentaryVehicle: $event}))}},model:{value:(_vm.filtersHeaders.commentaryVehicle),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, "commentaryVehicle", $$v)},expression:"filtersHeaders.commentaryVehicle"}})]}},{key:"item.report",fn:function(ref){
      var item = ref.item;
return _vm._l((item.companies),function(company,index){return _c('div',{key:index,staticClass:"py-2 separated-items"},_vm._l((item.reports),function(report,indexReport){return _c('div',{key:indexReport},[(report.company.id === company.id)?[_c('v-textarea',{attrs:{"no-resize":"","auto-grow":""},on:{"blur":function($event){return _vm.updatePropertyCommentaryTrainingOfficer(report.id, report.commentary)}},model:{value:(report.commentary),callback:function ($$v) {_vm.$set(report, "commentary", $$v)},expression:"report.commentary"}})]:_vm._e()],2)}),0)})}},{key:"item.eLearning",fn:function(ref){
      var item = ref.item;
return _vm._l((item.companies),function(company,index){return _c('div',{key:index,staticClass:"py-2 separated-items"},_vm._l((item.eLearnings),function(eLearning,indexELearning){return _c('div',{key:indexELearning},[(eLearning.company.id === company.id)?[_c('v-textarea',{attrs:{"no-resize":"","auto-grow":""},on:{"blur":function($event){return _vm.updatePropertyCommentaryTrainingOfficer(eLearning.id, eLearning.commentary)}},model:{value:(eLearning.commentary),callback:function ($$v) {_vm.$set(eLearning, "commentary", $$v)},expression:"eLearning.commentary"}})]:_vm._e()],2)}),0)})}},{key:"item.billing",fn:function(ref){
      var item = ref.item;
return _vm._l((item.companies),function(company,index){return _c('div',{key:index,staticClass:"py-2 separated-items"},_vm._l((item.billings),function(billing,indexBilling){return _c('div',{key:indexBilling},[(billing.company.id === company.id)?[_c('v-textarea',{attrs:{"no-resize":"","auto-grow":""},on:{"blur":function($event){return _vm.updatePropertyCommentaryTrainingOfficer(billing.id, billing.commentary)}},model:{value:(billing.commentary),callback:function ($$v) {_vm.$set(billing, "commentary", $$v)},expression:"billing.commentary"}})]:_vm._e()],2)}),0)})}},{key:"item.city",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.trainingLocation.city)+" ")]}},{key:"header.city",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.city')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingLocation.city': $event}))}},model:{value:(_vm.filtersHeaders['trainingLocation.city']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trainingLocation.city', $$v)},expression:"filtersHeaders['trainingLocation.city']"}})]}},{key:"header.trainingLocation.type",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('list-select-filter',{attrs:{"multiple":"","translation":_vm.$t('training_officers.list.filters.training_location_type'),"items":_vm.$appConfig.trainingLocationTypes},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingLocation.type': $event}))}},model:{value:(_vm.filtersHeaders['trainingLocation.type']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trainingLocation.type', $$v)},expression:"filtersHeaders['trainingLocation.type']"}})]}},{key:"item.trainingLocation",fn:function(ref){
      var item = ref.item;
return _vm._l((item.trainingSessions),function(trainingSession,index){return _c('div',{key:index,staticClass:"py-2 separated-items align-start"},[_c('div',[_vm._v(" "+_vm._s(trainingSession.trainingLocation.name)+" ")]),_c('div',[_vm._v(" "+_vm._s(trainingSession.trainingLocation.address)+" "+_vm._s(trainingSession.trainingLocation.zipCode)+" "+_vm._s(trainingSession.trainingLocation.city)+" ")])])})}},{key:"header.trainingLocation",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.training_location')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingSessions.trainingLocation.text': $event}))}},model:{value:(_vm.filtersHeaders['trainingSessions.trainingLocation.text']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trainingSessions.trainingLocation.text', $$v)},expression:"filtersHeaders['trainingSessions.trainingLocation.text']"}})]}},{key:"item.trainers",fn:function(ref){
      var item = ref.item;
return _vm._l((item.trainers),function(trainer,indexTrainer){return _c('v-chip',{key:indexTrainer,staticClass:"ma-1",attrs:{"color":"secondary"}},[_vm._v(" "+_vm._s(trainer.lastName)+" "+_vm._s(trainer.firstName)+" ")])})}},{key:"header.trainers",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.name')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainers.name': $event}))}},model:{value:(_vm.filtersHeaders['trainers.name']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trainers.name', $$v)},expression:"filtersHeaders['trainers.name']"}})]}},{key:"item.schedules",fn:function(ref){
      var item = ref.item;
return _vm._l((item.trainingSessions),function(trainingSession,index){return _c('div',{key:index,staticClass:"py-2 separated-items"},[_vm._v(" "+_vm._s(_vm._f("formatHour")(trainingSession.startAt))+" - "+_vm._s(_vm._f("formatHour")(trainingSession.endAt))+" ")])})}},{key:"item.needTrainingsNames",fn:function(ref){
      var item = ref.item;
return _vm._l((item.trainingSessions),function(trainingSession,index){return _c('div',{key:index,staticClass:"py-2 separated-items"},[(trainingSession.needTrainings.length === 0)?_c('div'):_c('div',_vm._l((trainingSession.needTrainings),function(needTraining,indexNeedTraining){return _c('div',{key:indexNeedTraining,staticClass:"height-client-trainee"},[_vm._v(" "+_vm._s(needTraining.trainee.lastName)+" "+_vm._s(needTraining.trainee.firstName)+" ")])}),0)])})}},{key:"header.needTrainingsNames",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.name')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingSessions.needTrainings.trainee.name': $event}))}},model:{value:(_vm.filtersHeaders['trainingSessions.needTrainings.trainee.name']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trainingSessions.needTrainings.trainee.name', $$v)},expression:"filtersHeaders['trainingSessions.needTrainings.trainee.name']"}})]}},{key:"item.needTrainingsPhones",fn:function(ref){
      var item = ref.item;
return _vm._l((item.trainingSessions),function(trainingSession,index){return _c('div',{key:index,staticClass:"py-2 separated-items"},[(trainingSession.needTrainings.length === 0)?_c('div',{staticClass:"height-client-trainee"}):_c('div',_vm._l((trainingSession.needTrainings),function(needTraining,indexNeedTraining){return _c('div',{key:indexNeedTraining,staticClass:"height-client-trainee"},[_vm._v(" "+_vm._s(needTraining.trainee.phoneNumber)+" "+_vm._s(needTraining.trainee.phoneNumber && needTraining.trainee.mobilePhone ? ' / ' : '')+" "+_vm._s(needTraining.trainee.mobilePhone)+" ")])}),0)])})}},{key:"header.needTrainingsPhones",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.phone')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingSessions.needTrainings.trainee.phones': $event}))}},model:{value:(_vm.filtersHeaders['trainingSessions.needTrainings.trainee.phones']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trainingSessions.needTrainings.trainee.phones', $$v)},expression:"filtersHeaders['trainingSessions.needTrainings.trainee.phones']"}})]}},{key:"item.needTrainingsEmails",fn:function(ref){
      var item = ref.item;
return _vm._l((item.trainingSessions),function(trainingSession,index){return _c('div',{key:index,staticClass:"py-2 separated-items"},[(trainingSession.needTrainings.length === 0)?_c('div'):_c('div',_vm._l((trainingSession.needTrainings),function(needTraining,indexNeedTraining){return _c('div',{key:indexNeedTraining,staticClass:"height-client-trainee"},[_vm._v(" "+_vm._s(needTraining.trainee.email)+" ")])}),0)])})}},{key:"header.needTrainingsEmails",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.email')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingSessions.needTrainings.trainee.email': $event}))}},model:{value:(_vm.filtersHeaders['trainingSessions.needTrainings.trainee.email']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trainingSessions.needTrainings.trainee.email', $$v)},expression:"filtersHeaders['trainingSessions.needTrainings.trainee.email']"}})],1)]}},{key:"item.client",fn:function(ref){
      var item = ref.item;
return _vm._l((item.companies),function(company,index){return _c('v-chip',{key:index,staticClass:"ma-1",attrs:{"color":"secondary"}},[_vm._v(" "+_vm._s(company.name)+" ")])})}},{key:"header.client",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.company')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingSessions.needTrainings.company.name': $event}))}},model:{value:(_vm.filtersHeaders['trainingSessions.needTrainings.company.name']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trainingSessions.needTrainings.company.name', $$v)},expression:"filtersHeaders['trainingSessions.needTrainings.company.name']"}})]}},{key:"item.client2",fn:function(ref){
      var item = ref.item;
return _vm._l((item.companies),function(company,index){return _c('div',{key:index,staticClass:"py-2 separated-items"},[_vm._v(" "+_vm._s(company.name)+" ")])})}},{key:"header.client2",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.company')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingSessions.needTrainings.company.name': $event}))}},model:{value:(_vm.filtersHeaders['trainingSessions.needTrainings.company.name']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trainingSessions.needTrainings.company.name', $$v)},expression:"filtersHeaders['trainingSessions.needTrainings.company.name']"}})]}},{key:"item.clientWithInitialsTrainingOfficer",fn:function(ref){
      var item = ref.item;
return _vm._l((item.companies),function(company,index){return _c('div',{key:index,staticClass:"py-2 separated-items"},[_vm._v(" "+_vm._s(company.name)+" "),_c('v-avatar',{staticClass:"text-caption",attrs:{"size":"30","color":"secondary"}},[_vm._v(" "+_vm._s(_vm.getInitialsAddedBy(item, company))+" ")])],1)})}},{key:"header.clientWithInitialsTrainingOfficer",fn:function(ref){
      var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('list-text-filter',{attrs:{"translation":_vm.$t('training_officers.list.filters.company')},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingSessions.needTrainings.company.name': $event}))}},model:{value:(_vm.filtersHeaders['trainingSessions.needTrainings.company.name']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trainingSessions.needTrainings.company.name', $$v)},expression:"filtersHeaders['trainingSessions.needTrainings.company.name']"}})]}},{key:"item.trainingType",fn:function(ref){
      var item = ref.item;
return _vm._l((item.trainingTypes),function(trainingType,index){return _c('v-chip',{key:index,attrs:{"color":"secondary"}},[_vm._v(" "+_vm._s(trainingType)+" ")])})}},{key:"header.trainingType",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('list-select-filter',{attrs:{"multiple":"","translation":_vm.$t('training_officers.list.filters.training_type'),"items":_vm.trainingTypesList,"item-text":function (item) { return item.name; },"item-value":function (item) { return item.id; }},on:{"filter-list":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trainingSessions.trainingType.id': $event}))}},model:{value:(_vm.filtersHeaders['trainingSessions.trainingType.id']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trainingSessions.trainingType.id', $$v)},expression:"filtersHeaders['trainingSessions.trainingType.id']"}})],1)]}},{key:"item.nbPax",fn:function(ref){
      var item = ref.item;
return [_vm._l((item.companies),function(company,index){return _c('div',{key:index,staticClass:"py-2 separated-items"},[_vm._v(" "+_vm._s(_vm.getNbPax(item.trainingSessions, company))+" ")])}),(item.companies.length === 0)?_c('div',{staticClass:"py-2 separated-items"},[_vm._v(" 0 ")]):_vm._e()]}},{key:"item.convention",fn:function(ref){
      var item = ref.item;
return _vm._l((item.companies),function(company,index){return _c('div',{key:index,staticClass:"py-2 separated-items"},_vm._l((item.trackingDates),function(trackingDate,indexTrackingDate){return _c('div',{key:indexTrackingDate},[(trackingDate.company.id === company.id)?[_c('date-field',{attrs:{"label":_vm.$t('training_officers.list.date'),"keep-hour":true,"rules":"","hide-details":"auto"},on:{"validate":function($event){return _vm.updateTrackingDateProperty(trackingDate.id, 'sendConventionAt', $event)}},model:{value:(trackingDate.sendConventionAt),callback:function ($$v) {_vm.$set(trackingDate, "sendConventionAt", $$v)},expression:"trackingDate.sendConventionAt"}})]:_vm._e()],2)}),0)})}},{key:"header.convention",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('date-field-filter',{attrs:{"label":_vm.$t('training_officers.list.filters.date')},on:{"validate":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trackingDateLogisticSessions.sendConventionAt': $event}))},"clear":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trackingDateLogisticSessions.sendConventionAt': null}))}},model:{value:(_vm.filtersHeaders['trackingDateLogisticSessions.sendConventionAt']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trackingDateLogisticSessions.sendConventionAt', $$v)},expression:"filtersHeaders['trackingDateLogisticSessions.sendConventionAt']"}})],1)]}},{key:"item.convocation",fn:function(ref){
      var item = ref.item;
return _vm._l((item.companies),function(company,index){return _c('div',{key:index,staticClass:"py-2 separated-items"},_vm._l((item.trackingDates),function(trackingDate,indexTrackingDate){return _c('div',{key:indexTrackingDate},[(trackingDate.company.id === company.id)?[_c('date-field',{attrs:{"label":_vm.$t('training_officers.list.date'),"keep-hour":true,"rules":"","hide-details":"auto"},on:{"validate":function($event){return _vm.updateTrackingDateProperty(trackingDate.id, 'sendConvocationAt', $event)}},model:{value:(trackingDate.sendConvocationAt),callback:function ($$v) {_vm.$set(trackingDate, "sendConvocationAt", $$v)},expression:"trackingDate.sendConvocationAt"}})]:_vm._e()],2)}),0)})}},{key:"header.convocation",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "+_vm._s(_vm.filtersHeaders['trackingDateLogisticSessions.sendConvocationAt'])+" "),_c('date-field-filter',{attrs:{"label":_vm.$t('training_officers.list.filters.date')},on:{"validate":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trackingDateLogisticSessions.sendConvocationAt': $event}))},"clear":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trackingDateLogisticSessions.sendConvocationAt': null}))}},model:{value:(_vm.filtersHeaders['trackingDateLogisticSessions.sendConvocationAt']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trackingDateLogisticSessions.sendConvocationAt', $$v)},expression:"filtersHeaders['trackingDateLogisticSessions.sendConvocationAt']"}})],1)]}},{key:"item.folderVehicle",fn:function(ref){
      var item = ref.item;
return [_c('date-field',{attrs:{"label":_vm.$t('training_officers.list.date'),"keep-hour":true,"rules":"","hide-details":"auto"},on:{"validate":function($event){return _vm.updateLogisticSessionProperty(item.id, 'sendVehicleFolderAt', item.sendVehicleFolderAt)}},model:{value:(item.sendVehicleFolderAt),callback:function ($$v) {_vm.$set(item, "sendVehicleFolderAt", $$v)},expression:"item.sendVehicleFolderAt"}})]}},{key:"header.folderVehicle",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('date-field-filter',{attrs:{"label":_vm.$t('training_officers.list.filters.date')},on:{"validate":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'sendVehicleFolderAt': $event}))},"clear":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'sendVehicleFolderAt': null}))}},model:{value:(_vm.filtersHeaders.sendVehicleFolderAt),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, "sendVehicleFolderAt", $$v)},expression:"filtersHeaders.sendVehicleFolderAt"}})],1)]}},{key:"item.smsAndMail",fn:function(ref){
      var item = ref.item;
return _vm._l((item.companies),function(company,index){return _c('div',{key:index,staticClass:"py-2 separated-items"},_vm._l((item.trackingDates),function(trackingDate,indexTrackingDate){return _c('div',{key:indexTrackingDate},[(trackingDate.company.id === company.id)?[_c('date-field',{attrs:{"label":_vm.$t('training_officers.list.date'),"keep-hour":true,"rules":"","hide-details":"auto"},on:{"validate":function($event){return _vm.updateTrackingDateProperty(trackingDate.id, 'sendSmsAndMailCLientAt', $event)}},model:{value:(trackingDate.sendSmsAndMailCLientAt),callback:function ($$v) {_vm.$set(trackingDate, "sendSmsAndMailCLientAt", $$v)},expression:"trackingDate.sendSmsAndMailCLientAt"}})]:_vm._e()],2)}),0)})}},{key:"header.smsAndMail",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('date-field-filter',{attrs:{"label":_vm.$t('training_officers.list.filters.date')},on:{"validate":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trackingDateLogisticSessions.sendSmsAndMailCLientAt': $event}))},"clear":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trackingDateLogisticSessions.sendSmsAndMailCLientAt': null}))}},model:{value:(_vm.filtersHeaders['trackingDateLogisticSessions.sendSmsAndMailCLientAt']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trackingDateLogisticSessions.sendSmsAndMailCLientAt', $$v)},expression:"filtersHeaders['trackingDateLogisticSessions.sendSmsAndMailCLientAt']"}})],1)]}},{key:"item.mailConcession",fn:function(ref){
      var item = ref.item;
return _vm._l((item.companies),function(company,index){return _c('div',{key:index,staticClass:"py-2 separated-items"},_vm._l((item.trackingDates),function(trackingDate,indexTrackingDate){return _c('div',{key:indexTrackingDate},[(trackingDate.company.id === company.id)?[_c('date-field',{attrs:{"label":_vm.$t('training_officers.list.date'),"keep-hour":true,"rules":"","hide-details":"auto"},on:{"validate":function($event){return _vm.updateTrackingDateProperty(trackingDate.id, 'sendMailCarDealerAt', $event)}},model:{value:(trackingDate.sendMailCarDealerAt),callback:function ($$v) {_vm.$set(trackingDate, "sendMailCarDealerAt", $$v)},expression:"trackingDate.sendMailCarDealerAt"}})]:_vm._e()],2)}),0)})}},{key:"header.mailConcession",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('date-field-filter',{attrs:{"label":_vm.$t('training_officers.list.filters.date')},on:{"validate":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trackingDateLogisticSessions.sendMailCarDealerAt': $event}))},"clear":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trackingDateLogisticSessions.sendMailCarDealerAt': null}))}},model:{value:(_vm.filtersHeaders['trackingDateLogisticSessions.sendMailCarDealerAt']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trackingDateLogisticSessions.sendMailCarDealerAt', $$v)},expression:"filtersHeaders['trackingDateLogisticSessions.sendMailCarDealerAt']"}})],1)]}},{key:"item.smsClient",fn:function(ref){
      var item = ref.item;
return _vm._l((item.companies),function(company,index){return _c('div',{key:index,staticClass:"py-2 separated-items"},_vm._l((item.trackingDates),function(trackingDate,indexTrackingDate){return _c('div',{key:indexTrackingDate},[(trackingDate.company.id === company.id)?[_c('date-field',{attrs:{"label":_vm.$t('training_officers.list.date'),"keep-hour":true,"rules":"","hide-details":"auto"},on:{"validate":function($event){return _vm.updateTrackingDateProperty(trackingDate.id, 'sendSmsAt', $event)}},model:{value:(trackingDate.sendSmsAt),callback:function ($$v) {_vm.$set(trackingDate, "sendSmsAt", $$v)},expression:"trackingDate.sendSmsAt"}})]:_vm._e()],2)}),0)})}},{key:"header.smsClient",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('date-field-filter',{attrs:{"label":_vm.$t('training_officers.list.filters.date')},on:{"validate":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trackingDateLogisticSessions.sendSmsAt': $event}))},"clear":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trackingDateLogisticSessions.sendSmsAt': null}))}},model:{value:(_vm.filtersHeaders['trackingDateLogisticSessions.sendSmsAt']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trackingDateLogisticSessions.sendSmsAt', $$v)},expression:"filtersHeaders['trackingDateLogisticSessions.sendSmsAt']"}})],1)]}},{key:"item.folderPostTrainingSession",fn:function(ref){
      var item = ref.item;
return _vm._l((item.companies),function(company,index){return _c('div',{key:index,staticClass:"py-2 separated-items"},_vm._l((item.trackingDates),function(trackingDate,indexTrackingDate){return _c('div',{key:indexTrackingDate},[(trackingDate.company.id === company.id)?[_c('date-field',{attrs:{"label":_vm.$t('training_officers.list.date'),"keep-hour":true,"rules":"","hide-details":"auto"},on:{"validate":function($event){return _vm.updateTrackingDateProperty(trackingDate.id, 'sendPostTrainingDocumentsAt', $event)}},model:{value:(trackingDate.sendPostTrainingDocumentsAt),callback:function ($$v) {_vm.$set(trackingDate, "sendPostTrainingDocumentsAt", $$v)},expression:"trackingDate.sendPostTrainingDocumentsAt"}})]:_vm._e()],2)}),0)})}},{key:"header.folderPostTrainingSession",fn:function(ref){
      var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(header.text)+" "),_c('date-field-filter',{attrs:{"label":_vm.$t('training_officers.list.filters.date')},on:{"validate":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trackingDateLogisticSessions.sendPostTrainingDocumentsAt': $event}))},"clear":function($event){return _vm.$emit('update-filters-headers', Object.assign({}, _vm.filtersHeaders, {'trackingDateLogisticSessions.sendPostTrainingDocumentsAt': null}))}},model:{value:(_vm.filtersHeaders['trackingDateLogisticSessions.sendPostTrainingDocumentsAt']),callback:function ($$v) {_vm.$set(_vm.filtersHeaders, 'trackingDateLogisticSessions.sendPostTrainingDocumentsAt', $$v)},expression:"filtersHeaders['trackingDateLogisticSessions.sendPostTrainingDocumentsAt']"}})],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }